@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'NanumSquareNeo';
  font-style: normal;
  font-weight: normal;
  src: local('NanumSquareNeo Regular'), local('NanumSquareNeo-Regular'),
        url('asset/font/NanumSquareNeo.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumSquareNeo';
  font-style: bold;
  font-weight: bold;
  src: local('NanumSquareNeo Regular'), local('NanumSquareNeo-Regular'),
       url('asset/font/NanumSquareNeo.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local('Raleway Regular'), local('Raleway-Regular'),
       url('asset/font/raleway.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway';
  font-style: bold;
  font-weight: 700;
  src: local('Raleway Regular'), local('Raleway-Regular'),
       url('asset/font/raleway.ttf') format('truetype');
}


/* react quill */
.ql-editor {
  border-radius: 0px 0px 15px 15px;
  /* height: 400px !important; */
}

.ql-toolbar {
  border-radius: 15px 15psx 0px 0px;
}

.ql-container.ql-snow {
  border: 1px solid rgb(107 114 128) !important;
  font-size: 15px;
}

@media (min-width: 1535px) {
  .ql-container.ql-snow {
      font-size: 18px
  }
}

.ql-toolbar.ql-snow {
  border: 1px solid rgb(107 114 128) !important;
  border-bottom: 0px solid white !important;
}

.ql-fill {
  color: #fff !important;
}

/* input tag browser offset */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}
input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
	-webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}